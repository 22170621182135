
import { defineComponent, PropType } from 'vue';
import { isOffice } from '@/methods/role';

interface PositionType {
    top: number;
    left: number;
}
export default defineComponent({
    props: {
        position: {
            type: Object as PropType<PositionType>,
            required: true
        }
    },
    setup() {
        return {
            isOffice: isOffice()
        };
    }
});
